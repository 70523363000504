import React from 'react'
import Layout from '../components/layout'
import EllisHelmet from '../components/EllisHelmet'
import { Link } from 'gatsby'

// import heroImage from '../assets/images/coffee.jpg'

const About = props => (
  <Layout>
    <EllisHelmet page={'about'} title={'About'} location={null} />
    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>About Ellis Island Coffee and Wine Lounge</h2>
          <p>
            "As a first generation immigrant myself I saw so much similarity in the emotions of those who arrived to the U.S. through Ellis Island at the beginning of the past century, and myself when I immigrated to the U.S."<br />
            - Murod Mamatov, owner
          </p>
        </header>
        <section id="content">
          {/* <img src={heroImage} alt="" /> */}
          <h2>In The Press</h2>
          <ul>
            <li><Link to="https://issuu.com/edmondchamber/docs/the_voice_fall_2020_final"><em>The Voice</em> - Fall 2020</Link></li>
			<li><Link to="https://www.news9.com/story/5e7e3c33065c486efd6d1b89/local-coffee-shop-says-pay-what-you-can-during-coronavirus-pandemic"><em>News 9</em> - Local Coffee Shop Says ‘Pay What You Can’ During Coronavirus Pandemic</Link></li>
			<li><Link to="https://www.news9.com/story/5ee1a0f51905200ba9e43934/phase-3-complete-businesses-are-open"><em>News 9</em> - Phase 3 Complete, Businesses Are Open</Link></li>
          </ul>
          <h2>Social Media</h2>
          <ul className="actions stacked">
			<li><a href="https://instagram.com/ellisislandcoffee" className="icon brands fa-instagram"> Instagram</a></li>
			<li><a href="https://www.facebook.com/Ellis-Island-Coffee-and-Wine-Lounge-110535787070690/" className="icon brands fa-facebook"> Facebook</a></li>
          </ul>
        </section>
      </div>
    </div>
  </Layout>
)

export default About
